<template>
  <div>
    <h1>Giới thiệu</h1>
    <ck-content
        id="lesson_content"
        @getData="updateContent"
        v-model="content"
        :content="content"
    ></ck-content>
    <v-row class="d-flex align-items-center justify-content-center mt-2">
      <v-btn color="primary" @click="updateSetting"
      >Submit
      </v-btn
      >
    </v-row>
  </div>
</template>
<script>
import CkContent from "@/components/ck-content";
import {GET_DETAIL_SETTING, UPDATE_SETTING} from "@/store/setting.module";
import {SET_LOADING} from "@/store/loading.module";
import {mapGetters} from "vuex";
import {SUCCESS} from "../../../store/alert.module";
import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";

export default {
  components: {
    CkContent,
  },
  data() {
    return {
      content: ''
    }
  },
  computed: {
    ...mapGetters({
      setting: "setting",
    }),
  },
  watch: {
    setting(newValue) {
      this.content = newValue.value
    }
  },
  async created() {
    await this.$store.dispatch(SET_LOADING, true);
    const payload = {
      key: 'introduce'
    }
    await  this.$store.dispatch(GET_DETAIL_SETTING, payload)
    await this.$store.dispatch(SET_LOADING, false);
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Giới thiệu", route: "Giới thiệu"},
    ]);
  },
  methods: {
    updateContent(data) {
      this.content = data
    },
    async updateSetting() {
      const payload = {
        key: 'introduce',
        value: this.content
      }
      await this.$store.dispatch(UPDATE_SETTING, payload)
      await this.$store.dispatch(SUCCESS, "Cập nhật thành công!", {
        root: true,
      });
    }
  }
}
</script>
